const tokenKey = process.env.VUE_APP_TOKEN_NAME // 使用env配置的 token存储键名

export function getToken () {
  return localStorage.getItem(tokenKey)
}

export function removeToken () {
  localStorage.removeItem(tokenKey)
}

export function setToken (token) {
  localStorage.setItem(tokenKey, token)
}
