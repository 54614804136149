import { getToken } from '@/utils/token'

import router from '@/router'

// import { getRoutesApi } from "@/api/router"; // 方式1：使用远程路由API接口
import jsonSrc from '../router/routes.json'// 方式2：使用本地json路由数据

export let cacheMenus = []
/**
 * 前置导航守卫Handle，处理token初步校验和动态注入路由
 */
export async function beforeEachHandle (to) {
  /* 非登录页校验token是否存在，若不存在重定向到登录页；其他页面通过交互接口校验，接口返回401时清理token并刷新页面，刷新后会重新触发beforeEach */
  if (to.path !== '/login' && !getToken()) {
    return {
      path: '/login', query: { from: to.path }, replace: true
    }
  }
  /* 非登录页时等待同步下载注入路由数据（内部判断缓存，有缓存return false）；登录页面不要注入（路由接口本身可能存在鉴权，可能会出现死循环） */
  if (to.path !== '/login' && await injectRoute()) {
    return to.fullPath // 成功注入路由后需要重定向一次
  }
}

/**
 * 动态注入路由
 */
export async function injectRoute () {
  if (cacheMenus && cacheMenus.length !== 0) {
    return false
  }
  const res = jsonSrc // 从本地json读取 需要设置 resolveJsonModule = true
  // const res = await routesApi() //从远程接口同步获取

  if (!res || res.length === 0) {
    return false
  }
  const routes = getRoutes(res)
  routes.forEach((i) => {
    router.addRoute(i)
  })
  cacheMenus = handleMenus(routes, null)
  console.log('inject-route-success')
  return true
}

/**
 * 解析远程或json返回的路由数据
 */
function getRoutes (items) {
  const i = []
  items.forEach((item) => {
    const injectComponent = () => {
      switch (item.type) {
        case 'layout':
          return import(/* webpackChunkName: "layout" */ `@/layout/${item.component}.vue`)
        default:
          return import(/* webpackChunkName: "views" */ `@/views/${item.component}.vue`)
      }
    }
    const r = {
      path: item.path,
      component: injectComponent,
      meta: {
        title: item.title ?? null,
        menu: item.menu ?? false,
        icon: item.icon ?? null,
        config: item.config ?? {}
      },
      children: item.children && item.children.length > 0 ? getRoutes(item.children) : [],
      redirect: item.redirect ?? null
    }
    i.push(r)
  })
  return i
}

/**
 * 路由数据解析为保存菜单数据
 */
function handleMenus (routes, parent) {
  const menus = []
  routes.forEach((item) => {
    const fullPath = parent && parent.path ? (parent.path === '/' ? parent.path + item.path : parent.path + '/' + item.path) : item.path
    const i = { children: [], path: '', title: '', icon: '', config: {} }
    i.title = item.meta.title ?? null
    i.icon = item.meta.icon ?? null
    i.config = item.meta.config ?? {}
    i.path = fullPath
    i.children = item.children && item.children.length > 0 ? handleMenus(item.children, item) : []
    if (item.meta.menu) {
      menus.push(i)
    }
  })
  return menus
}
