import { createRouter, createWebHashHistory } from 'vue-router'
import { beforeEachHandle } from '@/utils/router'

const routes = [ // 全局静态登录和404页面，其余页面动态注入
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/common/login-view.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/common/not-found.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.VUE_APP_RUN_PATH), // 使用hash模式，在env内自定义运行目录(线上部署可能使用二级目录)
  routes
})

/**
 * 前置导航守卫，负责处理动态路由和Token校验
 */
router.beforeEach(async (to) => {
  return await beforeEachHandle(to)
})

/**
 * 后置导航守卫，处理title和其它事务
 */
router.afterEach((to) => {
  const { title } = to.meta
  if (title && typeof title === 'string') {
    document.title = title
  }
})

export default router
