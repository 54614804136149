import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementIcons from '@element-plus/icons-vue'
import print from 'vue3-print-nb'

import zhCn from 'element-plus/es/locale/lang/zh-cn'

const app = createApp(App)
app.use(router)
app.use(print)

app.use(ElementPlus, {
  locale: zhCn
})
for (const iconName in ElementIcons) {
  if (Reflect.has(ElementIcons, iconName)) {
    const ic = ElementIcons
    const item = ic[iconName]
    app.component(`ElIcon${iconName}`, item)
  }
}
app.mount('#app')
